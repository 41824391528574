import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Chat, pubnub, MainWrapper } from 'wwtc-sdk';
import { endpointTranslate } from '../constants/connection';

function Main() {
    const history = useHistory();
    const userName = history.location.search.split('&id')[0].split('=')[1].replace(/%20/g, ' ');
    const language = history.location.search.split('&lng=')[1].split('&g')[0];

    useEffect(() => {
        pubnub.addListener({
            signal: function (s) {
                if (s.message === 'logout_admin') {
                    pubnub.unsubscribeAll();
                    history.goBack();
                }
            },
        });
        return () => {};
    }, []);

    return (
        <MainWrapper autologin={true} userName={userName} language={language}>
            <Header pubnub={pubnub} />
            <WrapperChat />
        </MainWrapper>
    );
}

function Header(props) {
    // initName comes from autologin component
    const { initName } = props;

    const handleLogout = () => {
        window.close();
    };

    return (
        <header>
            <div className="header-left-section">
                <div className="info-user">
                    <img src={`${process.env.PUBLIC_URL}/logo-header.png`} alt="WWTC" />
                </div>
            </div>
            <div className="header-center-section">
                <div className="direct-buttons hidden-mobile">
                    <span>LearnWithCuriosity 3.0</span>
                </div>
            </div>
            <div className="header-right-section">
                <div className="settings-user">
                    <span className="profile-name hidden-mobile">{initName}</span>
                    &nbsp;&nbsp;
                    <span
                        className="logout-btn"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Logout"
                        data-container="body"
                        onClick={handleLogout}
                    >
                        <i className="fas fa-sign-out-alt"></i>
                    </span>
                </div>
            </div>
        </header>
    );
}

function WrapperChat(props) {
    // values from autologin
    const { initName, initLanguage, initGender, conferenceName } = props;

    return (
        <div className="wrapper-components">
            <div style={{ width: '100%', height: '100%' }}>
                <Chat.ChatUI
                    username={initName}
                    initialSourceLanguage={initLanguage}
                    initialTargetLanguage={initLanguage}
                    initialVisible={true}
                    endpoint={endpointTranslate}
                    provider="pubnub"
                    speechRecognition={false}
                    channel={conferenceName}
                    style={stylesWrapper.chatContainer}
                    showButtonCloseComponent={false}
                />
            </div>
        </div>
    );
}

const stylesWrapper = {
    fullSubtitlesContainer: {
        width: 'calc(100% - 10px)',
        height: 'calc(100% - 10px)',
        margin: '5px auto',
    },
    subtitlesContainer: {
        width: 'calc(100% - 10px)',
        height: 'calc(50% - 10px)',
        margin: '5px auto',
    },
    chatContainer: {
        width: 'calc(100% - 10px)',
        height: 'calc(100% - 10px)',
        margin: '5px auto',
    },
};

export default Main;
