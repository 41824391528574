const endpointLogin = {
    url: 'https://ecsapi.worldwidetechconnections.com/api/Account',
    method: 'POST',
};

const endpointTranslate = {
    method: 'POST',
    url:
        'https://core.worldwidetechconnections.com/services/ttt/:sourceLanguage/:targetLanguage?text=:text',
    valueResponse: 'translated_text',
};

const endpointTextToSpeech = {
    method: 'POST',
    url:
        'https://core.worldwidetechconnections.com/services/tts/:sourceLanguage/:sourceLanguage?text=:text',
};

const endpointSpeechToText = {
    method: 'POST',
    url: 'https://core.worldwidetechconnections.com/services/stt/:sourceLanguage/:sourceLanguage',
    valueResponse: 'source_text',
    isFormData: false,
};

/*
const endpointSession = {
    method: 'GET',
    url: 'https://app.worldwidetechconnections.com/api/Session',
};
*/

const urlTestConnection = 'https://app.worldwidetechconnections.com/testmicrophone/index';

/*
const vendors = {
    ttt: [
        {
            vendor: 'microsoft',
            apiKey: 'Basic MsTransKey123',
            token: null,
        },
        {
            vendor: 'google',
            apiKey: 'Basic 111',
            token: null,
        },
        {
            vendor: 'deepl',
            apiKey: 'Basic 499dc93a-4c07-40bd-8119-2edb703b0300',
            token: null,
        },
        {
            vendor: 'yandex',
            apiKey: 'Basic 019be13b-9115-49ad-bfe4-8bb6e0a6dcf6',
            token: null,
        },
    ],
    stt: [
        {
            vendor: 'microsoft',
            apiKey: 'Basic MsSttKey123',
            token: null,
        },
        {
            vendor: 'nuance',
            apiKey: 'Basic NuanceSTT123',
            token: null,
        },
        {
            vendor: 'google',
            apiKey: 'Basic  1234567890',
            token: null,
        },
    ],
    tts: [
        {
            vendor: 'readspeaker',
            apiKey: 'Basic aa8b6f96-e2c6-4516-a4b3-ef92b1bc1ed9',
            token: null,
        },
        {
            vendor: 'nuance',
            apiKey: 'Basic e0933965-85d5-423c-989f-21a7e81d3e40',
            token: null,
        },
    ],
};
*/

export {
    endpointSpeechToText,
    endpointTextToSpeech,
    endpointTranslate,
    endpointLogin,
    urlTestConnection,
};
