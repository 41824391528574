import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Main from './components/Main.js';
import Chat from './components/Chat.js';
import Invitation from './components/Invitation.js';
import Login from './components/Login.js';

function App() {
    return (
        // If project will be store at subfolder
        // you should define the basename with the name of the folder
        // If project will be store at root
        // basename = undefined
        <Router basename="/">
            <Switch>
                <Route path="/chat">
                    <Chat />
                </Route>
                <Route path="/invitation">
                    <Invitation />
                </Route>
                <Route path="/app">
                    <Main />
                </Route>
                <Route path="/">
                    <Login />
                </Route>
            </Switch>
        </Router>
    );
}
export default App;
